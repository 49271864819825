import React from 'react';

import Layout, {
    bgImageDefault,
    Col2,
    PageHeaderA,
    PageHeaderB,
    PageSection,
} from '../components/layout';
import { PageText } from '../components/typography';
import { BoxLight } from '../components/box';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../components/seo';
import { SecondaryButton } from '../components/buttons';

function Services({ data, location }) {
    const { t } = useTranslation();
    const texts = [
        {
            title: t('architecture_title', 'Secure Architecture'),
            desc: t(
                'architecture_desc',
                'Angreifern genügt ein einziges Schlupfloch für einen erfolgreichen Angriff.'
            ),
            url: '/secure-architecture/',
        },
        {
            title: t('engineering_title', 'Secure Engineering'),
            desc: t(
                'engineering_desc',
                'Sicherheitsmaßnahmen, um die Daten eines Unternehmens vor Cyberangriffen, Verlust oder unbefugtem Zugriff zu schützen.'
            ),
            url: '/secure-engineering/',
        },
        {
            title: t('information_title', 'Information Security'),
            desc: t(
                'information_desc',
                'Geschäfts- und Betriebsgeheimnisse zu schützen, stellt eine der wichtigsten Aufgaben eines Unternehmens dar.'
            ),
            url: '/information-security/',
        },
        {
            title: t('testing_title', 'Security Testing'),
            desc: t(
                'testing_desc',
                'Vertrauen ist gut, Kontrolle ist besser. Schwachstellen identifizieren und deren Ausnutzung proaktiv verhindern.'
            ),
            url: '/security-testing/',
        },
    ];
    return (
        <Layout
            location={location}
            bgImage={{ file: bgImageDefault.services.file, opacity: 0.3 }}
            headerContent={
                <PageHeaderA
                    title={t('title', 'Professional Services')}
                    text={t(
                        'subtitle',
                        'condignum: Ihr Partner für digitale Sicherheit.'
                    )}
                    image={
                        <StaticImage
                            src="../images/headers/Professionalloop.png"
                            alt="Professional Services"
                            className="block max-w-sm"
                            objectFit="cover"
                            placeholder="none"
                            height={384}
                            quality={100}
                            formats={['auto', 'png']}
                        />
                    }
                />
            }
        >
            <Seo title={t('title', 'Services')} />
            <PageSection size="big" isDark className="pb-8">
                <PageText
                    className="max-w-4xl bg-gradient-to-r from-primary to-light-grey text-clean-white py-4 px-4 flex items-center justify-between"
                    type="smallCaps"
                >
                    Condignum Professional Services
                </PageText>
                <Col2>
                    {texts.map(t => (
                        <BoxLight
                            className="p-4 pb-6 flex flex-col justify-between items-start"
                            key={t.url}
                        >
                            <div className="mb-0">
                                <PageText type="highlighted" className="mb-0">
                                    {t.title}
                                </PageText>
                                <PageText>{t.desc}</PageText>
                            </div>

                            <SecondaryButton to={t.url} className="mt-2 px-6">
                                <Trans i18nKey="more">Mehr Erfahren</Trans>
                            </SecondaryButton>
                        </BoxLight>
                    ))}
                </Col2>
            </PageSection>
        </Layout>
    );
}

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["services"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default Services;
